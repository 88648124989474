import React from 'react';
import { Link } from 'react-router-dom';
import { Back } from '../components/iconSvg';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import '../styles/contactUs.scss';
import {
    Footer
} from '../components';

const ContactUs = () => {
    return (
        <>
            <section id='pageContactUs'>
                <div className='container-custom-tm'>
                    <Link to='/' className='my-4 d-block back-arrow'>
                        <Back />
                    </Link>
                    <h1 className='title'>
                        Hola, dinos ¿Cómo podemos ayudarte hoy con {process.env.REACT_APP_TITLE.replaceAll('-', ' ')}?{' '}
                        <img
                            src={
                                process.env.REACT_APP_URL_IMAGES_COMMONS +
                                'mano.png'
                            }
                            alt='mano'
                            className='ml-2'
                        />
                    </h1>
                    <section className='ld--card mb-5'>
                        <iframe
                            id="responsiveIframe" className="chatbot-container"
                            src='https://www.stack-ai.com/embed/ticketmundo/c9a85553-64aa-45d1-923d-d125825c2b2b/6525ecb672b493cf1fe4f383'
                            style={{ overflow: 'hidden', width: '100%', height: '635px', border: 'none', borderRadius: '10px' }} />
                        <Link to="/help/" className="link_to_help">
                            No consigo resolver mi requerimiento
                        </Link>
                    </section>
                </div>
                <ToastContainer />
            </section>
            <Footer />
        </>
    );
};

export default ContactUs;
