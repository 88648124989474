import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    FacebookShareButton,
    EmailShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ModalShare = ({ handleClose, show, linkFunc }) => {
    const [linkShared, setLinkShared] = useState(false);
    const notify = (text) => {
        // toast('Se copio elemento en el portapapeles');
        toast.info('Copiado a portapapeles', {
            position: toast.POSITION.BOTTOM_CENTER
        });
        setLinkShared(true);
    };

    useEffect(() => {
        setLinkShared(false);
    }, []);

    const showHideClassName = show ? 'show' : '';
    return (
        <div
            className={`modal fade modalShare ${showHideClassName}`}
            style={{ display: show ? 'block' : 'none' }}
            id="modalShare"
            role="dialog"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-md"
                role="document"
            >
                <div className="modal-content">
                    <div className="conten-share clear-fl">
                        <div
                            className="cerrar cerrar-shared"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span
                                className="cerrar"
                                onClick={handleClose}
                            >
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-x "
                                >
                                    <g>
                                        <line
                                            x1="18"
                                            y1="6"
                                            x2="6"
                                            y2="18"
                                        ></line>
                                        <line
                                            x1="6"
                                            y1="6"
                                            x2="18"
                                            y2="18"
                                        ></line>
                                    </g>
                                </svg>
                            </span>
                        </div>
                        <div className="content">
                            <div className="title">Compartir</div>
                            <div className="redes">
                                <div>
                                    <FacebookShareButton url={linkFunc}>
                                        <img src="/images/face.svg" alt="" />
                                    </FacebookShareButton>
                                </div>
                                {/* <div>
                                    <img src="/images/messenger.svg" alt="" />
                                </div> */}
                                <div>
                                    <LinkedinShareButton url={linkFunc}>
                                        <img
                                            src="/images/linkedin.svg"
                                            alt=""
                                        />
                                    </LinkedinShareButton>
                                </div>
                                <div>
                                    <TwitterShareButton url={linkFunc}>
                                        <img src="/images/twitter.svg" alt="" />
                                    </TwitterShareButton>
                                </div>
                                <div>
                                    <EmailShareButton url={linkFunc}>
                                        <img src="/images/mail.svg" alt="" />
                                    </EmailShareButton>
                                </div>
                            </div>
                            <div className="link">
                                <span>Copiar link</span>
                                <label>
                                    <label className="link-to-copy">
                                        {linkFunc}
                                    </label>
                                    <CopyToClipboard text={linkFunc}>
                                        <button
                                            onClick={() => notify(linkFunc)}
                                        >
                                            {linkShared ? 'Copiado' : 'Copiar'}
                                        </button>
                                    </CopyToClipboard>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ModalShare;
