import React from 'react';
import '../styles/App.sass';
import {
    Footer,
    Header,
    // Funciones,
    // Notas,
    // Intro,
    // Spotify,
    // Map,
    // Patrocinantes,
    Productoras,
    // CountDown,
    // PuntoDeVentas,
    // MetodosDePagos,
    Terminos
    // Galeria
    // Snacks,
} from '../components';
// import { Help } from '../components/iconSvg';
// import { Link } from 'react-router-dom';
// import moment from 'moment-timezone';

const ClickAquiButton = ({ color, withBackground, backgroundColor }) => {
    const finalColor = color === undefined ? 'white' : color
    const finalBG = withBackground === undefined ? false : withBackground
    const finalBackgroundColor = backgroundColor === undefined ? 'transparent' : backgroundColor

    return (
        <div style={{
            position: 'absolute',
            bottom: 20,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center'
        }}>
            <div style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: 20,
                border: `4px solid ${finalColor}`,
                padding: 5,
                color: finalColor,
                borderRadius: 17,
                background: finalBG ? finalBackgroundColor : "transparent",
            }}>
                Click Aquí
            </div>
        </div>
    )
}

const Home = () => {
    return (
        <>
            <Header
                urlBanner={`${process.env.REACT_APP_URL_IMAGES}banner-desktop.jpg`}
                urlBannerMovil={`${process.env.REACT_APP_URL_IMAGES}banner-movil.jpg`}
            />
            <div className="pt-5">
                <div className="container-custom-tm mb-5">
                    <h1 className="ld--title">Eventos</h1>
                    <div className="row"
                        style={{ justifyContent: 'center' }} // temporary style
                    >
                        {/* <div className="col-12 col-xl-3 d-flex flex-column mb-4" style={{ position: "relative" }}>
                            <a href="https://porloscaminosverdes2024.ticketmundo.com/" className='hoverAnim'>
                                <img style={{ display: "block", width: "100%", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_IMAGES}caminos-verdes.png`} />
                                <ClickAquiButton color={"white"} withBackground={true} backgroundColor={"#00000080"} />
                            </a>
                        </div> */}
                        <div className="col-12 col-xl-3 d-flex flex-column mb-4" style={{ position: "relative" }}>
                            <a href="https://ticketsve.ticketmundo.com/event/2927" className='hoverAnim'>
                                <img style={{ display: "block", width: "100%", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_IMAGES}emiliolovera.png`} />
                                <ClickAquiButton color={"white"} withBackground={true} backgroundColor={"#00000080"} />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xxl-4 col-xl-6 d-flex mb-4 align-items-stretch">
                            <Productoras />
                        </div>
                        <div className="col-12 col-xxl-8 col-xl-6 d-flex mb-4 ">
                            <Terminos
                                texto={
                                    <>
                                        1. Las entradas adquiridas son
                                        responsabilidad de la persona que
                                        efectúa la compra. En caso de mal uso o
                                        distribución de información de la compra
                                        a terceros, se exime de total
                                        responsabilidad a TICKETMUNDO.
                                        <br />
                                        2. El poseedor del ticket se obliga a
                                        cumplir las normas de seguridad
                                        aplicables al evento o recinto.
                                        <br />
                                        3. Deberá canjear este boleto
                                        electrónico por ticket físicos a
                                        petición exclusiva del productor del
                                        evento si fuese necesario. En estos
                                        casos será notificado del debido proceso
                                        con antelación vía correo electrónico
                                        y/o nuestras redes sociales. Si el
                                        titular de la compra no puede retirar
                                        la(s) entrada(s) para asistir al evento,
                                        puede realizar una autorización a
                                        terceros para realizar el canje de la
                                        misma {" "}
                                        <a
                                            href="mailto:https://cdn.ticketmundo.live/documentos/autorizacion-de-canje-ticketmundo.pdf"
                                            rel="noreferrer"
                                            style={{ color: `var(--bg__btn)` }}
                                        >
                                            https://cdn.ticketmundo.live/documentos/autorizacion-de-canje-ticketmundo.pdf
                                        </a>
                                        <br />
                                        4. Una vez realizada la compra de los
                                        boletos, los mismos no pueden ser
                                        cambiados, ni solicitar reembolso por la
                                        compra.
                                        <br />
                                        5. El productor(es) y/o organizador (es)
                                        se reservan el derecho de admisión y
                                        permanencia en el recinto de cualquier
                                        persona que perturbe la experiencia del
                                        evento por un comportamiento que altere
                                        su normal desarrollo, al igual que
                                        comida, bebidas u otros artículos
                                        prohibidos por el productor.
                                        <br />
                                        6. En caso de cancelación del
                                        espectáculo, será responsabilidad de la
                                        empresa productora la correspondiente
                                        devolución del monto pagado por las
                                        entradas, dicho monto no incluirá las
                                        comisiones cobradas por TICKETMUNDO.
                                        <br />
                                        7. En caso de compras con tarjetas
                                        internacionales, deberá enviar a través
                                        del correo de{' '}
                                        <a
                                            href="mailto:atencionalcliente@ticketmundo.com"
                                            rel="noreferrer"
                                            style={{ color: `var(--bg__btn)` }}
                                        >
                                            atencionalcliente@ticketmundo.com
                                        </a>
                                        , los siguientes recaudos para la
                                        validación de la compra: Foto de la
                                        cédula de identidad, pasaporte u otro
                                        documento de identificación. Imagen de
                                        la tarjeta utilizada para la compra,
                                        mostrando solo los 4 últimos dígitos.
                                        Planilla de validación:{' '}
                                        <a
                                            href="https://cdn.ticketmundo.live/documentos/Ticketmundo-Carta-Aceptacion-Cargo-Tarjeta-De-Credito-Forma-PV001.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ color: `var(--bg__btn)` }}
                                        >
                                            https://cdn.ticketmundo.live/documentos/Ticketmundo-Carta-Aceptacion-Cargo-Tarjeta-De-Credito-Forma-PV001.pdf
                                        </a>
                                        . En caso de consultas comunicarse a
                                        través del whatsapp AT{' '}
                                        <a
                                            href="https://wa.me/+584124286237"
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ color: `var(--bg__btn)` }}
                                        >
                                            +58 (412) 428-62-37
                                        </a>
                                        <br />
                                        8. Si por razones de fuerza mayor, no
                                        imputable al recinto o teatro, el día
                                        del evento la función se suspende,
                                        habiendo sido en parte presentada, no se
                                        realizará devolución alguna del valor de
                                        la entrada.
                                        <br />
                                        9. Menores de 18 años, deben de venir
                                        acompañados por un representante mayor
                                        de edad.
                                        <br />
                                        10. No se admite el ingreso de niños
                                        menores a 3 años
                                        <br />
                                        11. Todo niño a partir de 3 años debe
                                        adquirir ticket para ingresar al evento
                                        <br />
                                        12. Las compras en la Web podrán
                                        requerir canje por ticket físico según
                                        solicitud de la producción. TICKETMUNDO
                                        notificará la fecha de canje y lugares.
                                        <br />
                                    </>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;
