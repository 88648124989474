import React, { useState } from 'react';

import { LogoTM, Share } from './iconSvg';
import ModalShare from './ModalShare';
// import axios from 'axios';

const Header = ({ urlBanner, urlBannerMovil }) => {
    const [urlShort] = useState(window.location.href);
    const [showShare, setShowShare] = useState(false);
    const handleShare = async () => {
        // const { status, data } = await axios.post(`Configuracion/AcortarUrl`, {
        //     url: window.location.href
        // });
        setShowShare(true);
    };
    return (
        <header className="ld--header ">
            <div className="fixed-top">
                <div className="container-custom-tm">
                    <a href="https://ticketmundo.com.ve/">
                        <LogoTM />
                    </a>
                </div>
            </div>
            <figure className="urlBanner">
                <img src={urlBanner} alt="" />
            </figure>
            <figure className="urlBannerMovil">
                <img src={urlBannerMovil} alt="" />
            </figure>
            <div className="content-bottom">
                <div className="container-custom-tm">
                    <div className="wrap-button">
                        {/* <span className="info">Venta 22DIC Fans 8:30AM - Gral 11:00AM</span> */}
                        <button className="share" onClick={handleShare}>
                            <Share />
                        </button>
                    </div>
                </div>
            </div>
            <ModalShare
                linkFunc={urlShort}
                show={showShare}
                handleClose={() => {
                    setShowShare(false);
                }}
            />
        </header>
    );
};

export default Header;
