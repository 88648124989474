import React from 'react';
import { Email, Instagram, Whatsapp } from './iconSvg';

const Productoras = () => {
    return (
        <section className="ld--wrap-prods">
            <div className="ld--card">
                <h5>Produce</h5>
                <ul style={{ marginTop: '12px', maxHeight: `186px`, overflow: 'auto' }} className='px-0'>
                    <li className="produtore_list">
                        <div>
                            <img src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}lacomedialocal.png`} alt="lacomedialocal" />
                        </div>
                        <div>
                            <span className="span_productor">lacomedialocal</span>
                            <a href="https://www.instagram.com/lacomedialocal/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @lacomedialocal
                            </a>
                        </div>
                    </li>
                </ul>
                <div className="info-extra">
                    <h5>Atención al cliente</h5>
                    <div className="wtsp">
                        <Whatsapp/>
                        <a href="https://api.whatsapp.com/send?phone=584129174760">04129174760 (solo WhatsApp)</a>
                    </div>
                    <div className="email">
                        <Email/>
                        <a href="mailto:redes@lacomedialocal.com">redes@lacomedialocal.com</a>
                    </div>
                    <div className="rif">
                        <p>La Comedia Local</p>
                        <span>J-298669179</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Productoras;
