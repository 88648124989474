import React from 'react';
export const items = [
    // {
    //     id:'play',
    //     nombre:'Información General y Eventos Play',
    //     img:'play.svg',
    //     list:[
    //         {
    //             title:'Modalidades de compra de tickets y accesos',
    //             content: <div>
    //                 <p> Dispones de diversas formas de pago electrónico tanto en moneda local como internacional, lo cual facilita la experiencia de compra desde cualquier parte del mundo usando tu tarjeta crédito, débito, PayPal y Zelle. Si está en Venezuela dispones de opciones como Pago Móvil, tarjeta de crédito visa o MasterCard y débito Mercantil.</p>
    //                 <p>Si no ves el símbolo de tu moneda al momento del pago, selecciona USD y el sistema te hará el cobro al cambio en tu moneda local.</p>
    //             </div>
    //         },
    //         {
    //             title:'Descuentos, promociones y membresías',
    //             content: <div>
    //                 <p>Los códigos de descuentos y membresías son exclusivos de cada evento y depende de las empresas productoras y/o venues contratantes quienes hacen uso especial de este servicio para promocionar su espectáculo.  </p>
    //             </div>
    //         },
    //         {
    //             title:'Información de alianzas',
    //             content: <div>
    //                 Para obtener mayor información de alianzas o campañas promocionales debe comunicarse directamente a <a href='mailto:ventas-play@ticketmundo.com'>ventas-play@ticketmundo.com</a>
    //             </div>
    //         },
    //     ]
    // },
    // {
    //     id:'info',
    //     nombre:'Información de servicios de Ticketmundo Play',
    //     img:'info.svg',
    //     list:[
    //         {
    //             title:'Nuestros servicios',
    //             content: <div>
    //                 <p>Somos una plataforma streaming para eventos de todo tipo bajo modalidad Live Stream o VOD. </p>
    //             </div>
    //         },
    //         {
    //             title:'Contrataciones y/o promociones',
    //             content: <div>
    //                 <p>Para obtener información detallada de nuestros servicios de venta de contenido en streaming puedes ponerte en contacto con nuestros especialistas en la siguiente dirección: <a href='mailto:Ventas-play@ticketmundo.com'>Ventas-play@ticketmundo.com </a></p>
    //             </div>
    //         },
    //         {
    //             title:'¿Qué significa Cargo por Servicio?',
    //             content: <div>
    //                 <p>El cargo por servicio se refiere al costo de los servicios de la plataforma de streaming. </p>
    //             </div>
    //         },
    //     ]
    // },
    {
        id: 'soporte',
        nombre: 'Soporte al usuario',
        img: 'soporte.svg',
        list: [
            {
                title: '¿Que hacer el día del evento?',
                content: (
                    <div>
                        <p>
                            Debes motrar tu e-ticket (boleto electrónico) desde
                            tu smartphone, luego el personal de logística del
                            recinto va a scanear el código QR y te dará acceso a
                            la zona donde has comprado.{' '}
                        </p>
                    </div>
                )
            },
            {
                title: 'Realicé la compra y aún no recibo mis tickets.',
                content: (
                    <div>
                        <p>
                            El boleto electrónico fue enviado al email con el
                            que realizaste la compra. Para ubicar de manera
                            fácil tu email de compra puedes
                        </p>
                        <ul>
                            <li>
                                Buscar en tu bandeja de entrada un correo
                                recibido de la cuenta:{' '}
                                <a href='https://eticket-order.com'>
                                    eticket-order.com
                                </a>
                            </li>
                            <li>
                                Hacer una búsqueda en tu manejado de correo de
                                la palabra “Vinotinto”, si no lo consigues por
                                ninguna de esta vías, valida que estes revisando
                                la bandeja de entrada del email con el que
                                compraste o revisa tu carpeta de SPAM o de
                                promociones.
                            </li>
                            <li>
                                Si después de hacer estas revisiones tu problema
                                persiste,{' '}
                                <a href='https://ticketmundo.com'>haz click</a>{' '}
                                aquí para intentes reenviarte tu tickets
                                electronicos.
                            </li>
                        </ul>
                    </div>
                )
            },
            {
                title: 'Modificación de datos de usuarios',
                content: (
                    <div>
                        <p>
                            Para realizar alguna corrección de su correo
                            electrónico o número de contacto, debe escribir al
                            Departamento de Atención al Cliente para ser
                            asistido.{' '}
                        </p>
                    </div>
                )
            },
            {
                title:
                    'Reenvío de e-ticket (confirmación de compra electrónica)',
                content: (
                    <div>
                        <p>
                            Si al finalizar su compra no recibe su e-ticket con
                            la información de sus boletos o lo has borrado por
                            error. Te recomendamos revisar la carpeta de spam,
                            correos no deseados, carpeta de promociones (Gmail),
                            junk mail o escribir al Departamento de Atención al
                            Cliente para ser asistido.{' '}
                        </p>
                    </div>
                )
            },
            {
                title:
                    'El sitio web no me carga correctamente, no muestra las opciones de pago o me dice error de código de acceso.',
                content: (
                    <div>
                        <p>
                            Verifica que no estés conectado a una red privada de
                            internet o VPN. Nuestro sitio web por seguridad
                            requiere que te conectes directamente a tu proveedor
                            de Internet para conocer tu geolocalización real.
                            Sugerimos usar Google Chrome como navegador
                            principal.
                        </p>
                        <p>
                            <b>Pruebas alguna de estas opciones:</b>
                        </p>
                        <ul>
                            <li>Recargar la página.</li>
                            <li>Borra los datos del navegador.</li>
                            <li>No uses WiFi público.</li>
                            <li>Prueba el modo incógnito.</li>
                            <li>Desactiva temporalmente tu VPN.</li>
                        </ul>
                    </div>
                )
            }
        ]
    },
    {
        id: 'payment',
        nombre: 'Métodos de Pagos y Verificaciones',
        img: 'payment.svg',
        list: [
            {
                title: 'Modalidades de compra de tickets y accesos',
                content: (
                    <div>
                        <p>
                            Dispones de diversas formas de pago electrónico
                            tanto en moneda local como internacional, lo cual
                            facilita la experiencia de compra desde cualquier
                            parte del mundo usando tu tarjeta crédito, débito,
                            PayPal y Zelle. Si está en Venezuela dispones de
                            opciones como: Pago Móvil, tarjeta de crédito visa o
                            MasterCard y débito Mercantil.
                        </p>

                        <p className='text-center'>
                            <b>
                                Si no ves el símbolo de tu moneda al momento del
                                pago, selecciona USD y el sistema te hará el
                                cobro al cambio en tu moneda local.
                            </b>
                        </p>
                    </div>
                )
            },
            // {
            //     title:'¿Qué es pago móvil?',
            //     content: <div>
            //         <p>Es un servicio de pago fácil exclusivo para clientes en el territorio de Venezuela.</p>
            //     </div>
            // },
            {
                title: 'Realicé un pago móvil y no me dejar registrarlo. ',
                content: (
                    <div>
                        <p>Debes verificar los siguientes factores: </p>
                        <ul>
                            <li>
                                Haber realizado el pago por el monto exacto que
                                te aparece en la página, éste debe incluir los
                                decimales sin redondear las cifras.
                            </li>
                            <li>
                                El número de teléfono debe ser el asociado al
                                titular de la cuenta de pago móvil de donde
                                realizaste el pago.{' '}
                            </li>
                            <li>
                                La C.I. debe ser la del titular de la cuenta de
                                pago móvil.
                            </li>
                            <li>
                                Coloca el número de referencia sin espacios.{' '}
                            </li>
                        </ul>
                        <p>
                            En caso de soporte adicional debes escribir al{' '}
                            <a href='mailto:atencionalcliente@ticketmundo.com'>
                                atencionalcliente@ticketmundo.com
                            </a>{' '}
                            con todos los soportes necesario para verificar tu
                            pago.
                        </p>
                    </div>
                )
            },
            {
                title: '¿Cuánto tiempo toma aprobar un pago a través de Zelle?',
                content: (
                    <div>
                        <p>
                            Los pagos en Zelle se validan en tiempo real y de
                            forma automatizada de manera que{' '}
                            <b>
                                es muy importante registrar el pago en nuestra
                                plataforma una vez que se ejecute el pago para
                                recibir tus tickets.
                            </b>{' '}
                        </p>
                        <p>
                            Te aconsejamos seguir los siguientes pasos para
                            garantizar una compra exitosa con Zelle:
                        </p>
                        <ul>
                            <li>
                                Ingresa al portal web y selecciona la cantidad
                                de tickets en tu zona de preferencia.
                            </li>
                            <li>
                                Selecciona comprar acceso y llega hasta la
                                pantalla de pago
                            </li>
                            <li>
                                En los métodos de pago, selecciona Zelle como
                                opción de pago y revisa las instrucciones para
                                el pago.
                            </li>
                            {/* <li>El pago se debe realizar a la cuenta: zelle@lukapagos.com</li> */}
                            <li>
                                El pago debe ser por el monto exacto que se
                                muestra en la página (Ej. $17.25)
                            </li>
                            <li>
                                Una vez ejecutado el pago debes registrar el
                                pago en la plataforma haciendo click en el botón
                                “Registrar Pago” ingresando:
                                <ol>
                                    <li>
                                        Nombre de la persona dueña de la cuenta
                                        de Zelle que realizó el pago
                                    </li>
                                    <li>
                                        Email asociado a la cuenta de Zelle que
                                        realizó el pago
                                    </li>
                                    <li>
                                        Número de confirmación de la transacción
                                        emitido por el Banco al momento del pago{' '}
                                        <smal>
                                            (Generalmente es un código
                                            alfanumérico entre 7 y 10
                                            caracteres){' '}
                                        </smal>
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </div>
                )
            },
            {
                title:
                    'Ocurrió un error al momento de mi compra en internet ¿Cómo puedo asegurarme de que se realizó la compra y el cargo a mi tarjeta?',
                content: (
                    <div>
                        <p>
                            <b>No intentes comprar nuevamente.</b> Primero
                            revisa que hayas recibido un correo con la
                            confirmación de comprar.{' '}
                        </p>
                        <p>
                            Recuerda verificar en recibido, spam o Junk mail.
                            Para mayor asistencia puedes escribirnos a{' '}
                            <a href='mailto:atencionalcliente@ticketmundo.com'>
                                atencionalcliente@ticketmundo.com
                            </a>{' '}
                            y enviarnos la siguente información:
                        </p>
                        <p>Datos del titular del instrumento de pago:</p>
                        <ul>
                            <li>Nombre completo:</li>
                            <li>Teléfono:</li>
                            <li>Correo usado en la compra:</li>
                            <li>Fecha:</li>
                            <li>
                                Soporte de pago donde se vea claramente el
                                débito en la cuenta y código de referencia:
                            </li>
                            <li>Método utilizado:</li>
                        </ul>
                    </div>
                )
            }
        ]
    }
];
