import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const MetodosDePagos = ({ metodos = [] }) => {
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + ' buttonsGallery"></span>';
        }
    };
    return (
        <>
            <section className="ld--intro ld--card ld--card--mpa">
                <h2 className="ld--title">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                        <path d="M11.9997 21.2712C17.1201 21.2712 21.2709 17.1203 21.2709 12C21.2709 6.87962 17.1201 2.72876 11.9997 2.72876C6.87937 2.72876 2.72852 6.87962 2.72852 12C2.72852 17.1203 6.87937 21.2712 11.9997 21.2712Z" stroke="#24292E" strokeWidth="1.13" strokeMiterlimit="10"/>
                        <path d="M12 7.00073V17.0003" stroke="#24292E" strokeWidth="1.13" strokeMiterlimit="10" strokeLinecap="round"/>
                        <path d="M14.842 9.08153C14.4761 8.70469 14.0321 8.41267 13.5411 8.22607C13.0501 8.03947 12.5242 7.96282 12.0004 8.00153C9.3244 7.76153 6.694 11.4191 12.0004 12.0011C16.7236 12.3179 15.1204 16.2011 12.0004 16.0007C11.4908 15.9945 10.9875 15.8874 10.5195 15.6855C10.0516 15.4837 9.62825 15.1911 9.274 14.8247" stroke="#24292E" strokeWidth="1.13" strokeMiterlimit="10" strokeLinecap="round"/>
                    </svg> */}
                    Métodos de Pagos aceptados
                </h2>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    pagination={pagination}
                    navigation={true}
                    breakpoints={{
                        640: {
                            spaceBetween: 10,
                            slidesPerView: 3
                        },
                        768: {
                            spaceBetween: 10,
                            slidesPerView: 3
                        },
                        1024: {
                            spaceBetween: 10,
                            slidesPerView: 5
                        },
                        1200: {
                            spaceBetween: 10,
                            slidesPerView: 7
                        },

                    }}
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    {metodos.map((m, idx) => (
                        <>
                            {m.includes('Pago Movil') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/pago-movil-landing.svg'
                                            }
                                            alt="pm-c2p"
                                        />
                                    </div>
                                    Pago Móvil Bs
                                </SwiperSlide>
                            )}
                            {m.includes('bdv') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/bdv-logo-oficial.png'
                                            }
                                            alt="bdv"
                                        />
                                    </div>
                                    Banco de Venezuela Bs
                                </SwiperSlide>
                            )}
                            {m.includes('bancamiga') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/bancamiga-landing.svg'
                                            }
                                            alt="bancamiga"
                                        />
                                    </div>
                                    Débito Bancamiga Bs
                                    <br />
                                    (Consorcio)
                                </SwiperSlide>
                            )}
                            {m.includes('Bancaribe') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/bancaribe-landing.svg'
                                            }
                                            alt="bancaribe"
                                        />
                                    </div>
                                    Débito Bancaribe Bs
                                    <br />
                                    (Consorcio)
                                </SwiperSlide>
                            )}
                            {m.includes('bancrecer') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/bancrecer-landing.png'
                                            }
                                            alt="bancrecer"
                                        />
                                    </div>
                                    Débito Bancrecer Bs
                                    <br />
                                    (Consorcio)
                                </SwiperSlide>
                            )}
                            {m.includes('MiBanco') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/mibanco-landing.png'
                                            }
                                            alt="mibanco"
                                        />
                                    </div>
                                    Débito Mi Banco Bs
                                    <br />
                                    (Consorcio)
                                </SwiperSlide>
                            )}
                            {m.includes('banfanb') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/banfanb-landing.png'
                                            }
                                            alt="banfanb"
                                        />
                                    </div>
                                    Débito BANFANB Bs
                                    <br />
                                    (Consorcio)
                                </SwiperSlide>
                            )}
                            {m.includes('banco del tesoro') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/banco-del-tesoro-landing.png'
                                            }
                                            alt="banco del tesoro"
                                        />
                                    </div>
                                    Débito Banco Del Tesoro Bs
                                    <br />
                                    (Consorcio)
                                </SwiperSlide>
                            )}
                            {m.includes('Crédito') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/cards-landing.svg'
                                            }
                                            alt="cards-landing"
                                        />
                                    </div>
                                    Crédito/Debito USD
                                </SwiperSlide>
                            )}
                            {m.includes('Efectivo') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/cash-landing.svg'
                                            }
                                            alt="cash"
                                        />
                                    </div>
                                    Efectivo USD <br />
                                    (solo taquilla)
                                </SwiperSlide>
                            )}
                            {/* {m.includes('Zelle') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/zelle-landing.svg'
                                            }
                                            alt="zelle"
                                        />
                                    </div>
                                    Zelle USD
                                </SwiperSlide>
                            )} */}
                            {m.includes('Applepay') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/applepay-landing.svg'
                                            }
                                            alt="applepay"
                                        />
                                    </div>
                                    Appel Pay USD
                                </SwiperSlide>
                            )}
                            {m.includes('Paypal') && (
                                <SwiperSlide key={idx}>
                                    <div className="img--container">
                                        <img
                                            src={
                                                'https://cdn.ticketmundo.live/commons/paypal-landing.svg'
                                            }
                                            alt="Paypal"
                                        />
                                    </div>
                                    Paypal USD
                                </SwiperSlide>
                            )}
                        </>
                    ))}
                </Swiper>
                {/* <ul className="ld--metodo">
                    {metodos.map((m, idx) => (
                        <li key={idx}>
                            <h2 className="ld--metodo--title">{m.moneda}</h2>
                            <ul className="ul_metodo">
                                {
                                    m?.tipos.includes('Pago Movil') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/pm-c2p.svg'} alt="pm-c2p" />
                                        </div>
                                        Pago Movil
                                    </li>
                                }
                                {
                                    m?.tipos.includes('C2P') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/pm-c2p.svg'} alt="pm-c2p" />
                                        </div>
                                        C2P
                                    </li>
                                }
                                {
                                    m?.tipos.includes('BNC Debito') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/BNC.svg'} alt="credit-cards" />
                                        </div>
                                        BNC Debito
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Mercantil') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/mercantil.png'} alt="mercantil" />
                                        </div>
                                        Mercantil
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Banesco') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/banesco.png'} alt="banesco" />
                                        </div>
                                        Banesco
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Tarjeta Credito') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/credit-cards.svg'} alt="banesco" />
                                        </div>
                                        Tarjeta Credito
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Paypal') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/paypal.svg'} alt="paypal" />
                                        </div>
                                        Paypal
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Zelle') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/zelle.png'} alt="Zelle" />
                                        </div>
                                        zelle
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Efectivo') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/cash.svg'} alt="cash" />
                                        </div>
                                        Efectivo
                                    </li>
                                }
                            </ul>
                        </li>
                    ))}
                </ul> */}
            </section>
        </>
    );
};

export default MetodosDePagos;
