import React from 'react';
const Terminos = ({ texto }) => {
    return (
        <section className="ld--wrap-terminos">
            <div className="ld--card">
                <div className="ld--title">Términos y Condiciones</div>
                <div className="content">
                    <p>{texto}</p>
                </div>
            </div>
        </section>
    );
};

export default Terminos;
