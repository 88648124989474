import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Back, Angle } from './iconSvg';
import play from '../images/play.svg';
import payment from '../images/tarjetas.png';
import soporte from '../images/soporte.png';
import info from '../images/info.svg';

const ItemSelected = ({ item }) => {
    const [Active, setActive] = useState(null);
    const [Img, setImg] = useState('');
    useEffect(() => {
        const _I =
            item.id === 'payment'
                ? payment
                : item.id === 'play'
                    ? play
                    : item.id === 'soporte'
                        ? soporte
                        : info;
        setImg(_I);
    }, []);
    return (
        <div className='itemSelected'>
            <Link to='/help/' className='my-4 d-block back-arrow'>
                <Back />
            </Link>
            <h3>
                <span>{item.nombre}</span>
                <img src={Img} alt='' />
            </h3>
            <div className='faqs'>
                {item.list.map((l, index) => {
                    return (
                        <div className='faq' key={index}>
                            <div
                                className='titleF'
                                onClick={() =>
                                    Active === index
                                        ? setActive(null)
                                        : setActive(index)
                                }
                            >
                                {l.title}{' '}
                                <span
                                    className={`angle ${
                                        Active === index ? 'active' : ''
                                    }`}
                                >
                                    <Angle />
                                </span>
                            </div>
                            <div
                                className={`content ${
                                    Active === index ? 'active' : ''
                                }`}
                            >
                                {l.content}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ItemSelected;
